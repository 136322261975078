import Row  from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import Col  from "react-bootstrap/Col"
import telegram_logo from "../static/logo/telegram.png"
import { useEffect, useState } from "react"

function ContactUs(){
    const [contactPhone, setContactPhone] = useState(null);
    const phoneURL = 'https://radioramezan.com/mobile/api/phoneNumber';
    useEffect(()=>{
        fetch(phoneURL)
        .then(res => res.json())
        .then(res => {
            if (res['phone_number'] != null){
                setContactPhone(res['phone_number'])
            }
        })
    },[setContactPhone])
    function sendButtonClicked(e){
        e.target.classList.add('waiting');

    }

    return(
        <>
        <div className="contact-us-container">
        <div className="box-container">
            <h1>ارتباط با ما</h1>
            <h3>ارتباط از طریق ارسال ایمیل</h3>
            <Form method="post" action="https://radioramezan.com/mobile/api/contact.php" id="contact_form">
                <Row>
                    <Col sm="4">
                    </Col>
                    <Col sm="4">
                        <label htmlFor="name">نام و نام خانوادگی</label>
                        <Form.Control className="mb-3" name="contact-name-field" id="name" type="text" placeholder="مثل: احمد احمدی" required/>
                        <label htmlFor="email">ایمیل</label>
                        <Form.Control className="mb-3" name="contact-email-field" id="email" type="email" placeholder="name@example.com" required/>
                        <label htmlFor="subject">موضوع ایمیل</label>
                        <Form.Control className="mb-3" name="contact-subject-field" id="subject" type="text" placeholder="موضوع ایمیل" required/>
                        <label htmlFor="message">پیام</label>
                        <Form.Control className="mb-3" as="textarea" name="contact-message-text-area" id="message" type="text" placeholder="متن پیام" required/>
                        <button type="button" className="g-recaptcha" onClick={sendButtonClicked}
                            data-sitekey="6LfH4TEpAAAAAG1RCgY-8RtAxv4b9qoPMIp5aULn"
                            data-callback='onSubmit'
                            data-action='submit'><span className="waiting-btn"><span className="waiting-spinner"></span>لطفا صبر کنید ...</span><span className="normal-btn">ارسال</span></button>
                    </Col>
                    <Col sm="4">
                    </Col>
                </Row>
            </Form>
            <hr className="contact-seprator" style={{marginTop:'2em',}}></hr>
            <h3>راه‌های ارتباطی دیگر</h3>
            <div className="other-contacts-container">
                <div className="other-contacts-item">
                    <div className="other-contacts-head">
                        <span>ارتباط مستقیم با ادمین رادیو رمضان</span>  
                    </div>
                    <div className="other-contacts-data">
                        <a href="https://t.me/RadioRamezan2" style={{ textDecoration: 'none' }}>
                            <img style={{ verticalAlign: "middle", }} src={telegram_logo} alt="Telegram-logo" width="25px" />
                        </a>
                    </div>
                </div>
                <div className="other-contacts-item">
                    <div className="other-contacts-head">
                        <span>ارتباط از طریق ایمیل</span>
                    </div>
                    <div className="other-contacts-data">
                        <a href="mailto:radio.ramezan@gmail.com" style={{ textDecoration: 'none' }}>radio.ramezan@gmail.com</a>
                    </div>
                </div>
                <div className="other-contacts-item">
                    <div className="other-contacts-head">
                        <span>تلفن</span>
                    </div>
                    <div className="other-contacts-data" style={{direction:'ltr'}}>
                        {
                            contactPhone != null ? (
                                <span>{contactPhone}</span>
                            ) : (
                                <span>--</span>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    )

}

export default ContactUs