
function ClockDigital(props){
    return(
        <div className="digital-clock-container">
            <div className="digits-container">
                <span>{(props.time.getHours() === 12 ? 12 : props.time.getHours() % 12).toLocaleString('en-us',{minimumIntegerDigits:2})}</span>
                <span className={(props.time.getSeconds() % 2 === 0) ? 'visibile-cl' : 'hidden-cl'} >:</span>
                <span>{(props.time.getMinutes()).toLocaleString('en-us', {minimumIntegerDigits:2})}</span>
            </div>
            <div className="ampm-container">
                <div>{Math.floor(props.time.getHours() / 12) === 0 ? 'AM' : 'PM'}</div>
            </div>
        </div>
        )
    }
    
export default ClockDigital