// import logo from './logo.svg';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Heading from './components/Heading';
import Nav from './components/Nav';
import './App.css';
import './static/fonts/rtlfont.css';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import PlayerStatProvider from './components/PlayerStatProvider';
import PlayerContainerProvider from './components/PlayerContainerProvider';
import PlayerControlFooter from './components/PlayerControlFooter';
import Alert from './components/Alert';


function App() {
  return (
    <>
    <PlayerStatProvider>
      <Heading title='رادیو رمضان' />
      <Nav />
      <div className='player-provider-container'>
        <PlayerContainerProvider />
        <Alert />
      </div>
      <div className='main-container'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/contact-us' element={<ContactUs />} />
        </Routes>
      </div>
      <div className='footer-player'>
        <PlayerControlFooter />
      </div>
    </PlayerStatProvider>
    </>
    );
  }
  
  export default App;
  