import Cookies from "js-cookie";
function CustomSelector(props){
    var options = null;
    if (props.options != null)
        options = props.options.map((value,index)=>{
            return <option key={`${props.name}$${value[`${props.name}_id`]}`} value={value[`${props.name}_id`]} >{value[`${props.name}_name_fa`]}</option>
        });
    
    function onChangeSelect(e){
        const ch_url = props.options.find(x => x[`${props.name}_id`] === e.target.value).url
        if (ch_url != null){
            props.setChoice([e.target.value, e.nativeEvent.target[e.target.selectedIndex].text, ch_url])
            setCookieOnChange([e.target.value, e.nativeEvent.target[e.target.selectedIndex].text, ch_url])
        } else{
            props.setChoice([e.target.value, e.nativeEvent.target[e.target.selectedIndex].text])
            setCookieOnChange([e.target.value, e.nativeEvent.target[e.target.selectedIndex].text])
        }
        // console.log(ch_url);
    }
    function setCookieOnChange(value){
        Cookies.set('set_' + props.name, JSON.stringify(value));
        // console.log(value);
    }
    // const objEntries = Object.entries(props.options)
    // console.log(objEntries);
    
    return (
        options != null ?
        <div className="custom-selector-container">
            <label htmlFor={props.name + "_selector_id"}>{props.label}</label><br/>
            <select onChange={onChangeSelect} onChangeCapture={onChangeSelect} name={props.name + "_selector"} id={props.name + "_selector_id"} className={props.name + "-selector custom-selector"} defaultValue={props.choice[0]}>
            {options}
            </select>
        </div> : 
        <div className="custom-selector-container">
            <label htmlFor={props.name + "_selector_id"}>{props.label}</label><br />
            <select id={props.name + "_selector_id"} className={props.name + "-selector custom-selector"}>
                <option key="0" disabled>
                    لطفا صبر کنید
                </option>
            </select>
        </div>
    )
}

export default CustomSelector