function Equalizer(props){

    return(
        <svg className={props.stat ? '' : 'pause'} id="circular_equalizer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="200" >
            <g transform="translate(250 0) rotate(0 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(6 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(12 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(18 3 250)">
                <rect id="eq_rect_3" x="0" y="-90" width="6" height="70" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(24 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(30 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(36 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(42 3 250)">
                <rect id="eq_rect_3" x="0" y="-90" width="6" height="70" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(48 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(54 3 250)">
                <rect id="eq_rect_3" x="0" y="-90" width="6" height="70" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(60 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(66 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(72 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(78 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(84 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(90 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(96 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(102 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(108 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(114 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(120 3 250)">
                <rect id="eq_rect_3" x="0" y="-90" width="6" height="70" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(126 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(132 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(138 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(144 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(150 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(156 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(162 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(168 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(174 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(180 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(186 3 250)">
                <rect id="eq_rect_3" x="0" y="-90" width="6" height="70" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(192 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(198 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(204 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(210 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(216 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(222 3 250)">
                <rect id="eq_rect_3" x="0" y="-90" width="6" height="70" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(228 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(234 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(240 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(246 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(252 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(258 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(264 3 250)">
                <rect id="eq_rect_3" x="0" y="-90" width="6" height="70" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(270 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(276 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(282 3 250)">
                <rect id="eq_rect_3" x="0" y="-90" width="6" height="70" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(288 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(294 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(300 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(306 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(312 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(318 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(324 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(330 3 250)">
                <rect id="eq_rect_1" x="0" y="-90" width="6" height="22" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(336 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(342 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(348 3 250)">
                <rect id="eq_rect_4" x="0" y="-90" width="6" height="35" transform=" scale(1 -1)" fill="#822C61" />
            </g>
            <g transform="translate(250 0) rotate(354 3 250)">
                <rect id="eq_rect_2" x="0" y="-90" width="6" height="50" transform=" scale(1 -1)" fill="#822C61" />
            </g>
        </svg>
    )
}

export default Equalizer