import alert2 from '../static/audio/azanAlarm2.mp3'
import alert5 from '../static/audio/azanAlarm5.mp3'
import alert10 from '../static/audio/azanAlarm10.mp3'
import alert20 from '../static/audio/azanAlarm20.mp3'
import { usePlayerStatContext } from "./PlayerStatProvider"
import { useRef } from 'react'
import {Howl} from 'howler';

function Alert(){
    const { approchingEvent, activeOghat, player, playerRef } = usePlayerStatContext();
    const [playerStat,setPlayerStat] = player;
    // const playerRef2 = useRef(null);
    // const playerRef5 = useRef(null);
    // const playerRef10 = useRef(null);
    // const playerRef20 = useRef(null);
    const oldPlayerState = useRef(false);
    const minShift = useRef(false);

    var alertSound20 = new Howl({
        src: [alert20],
        onend: onAlertStop
    });
    var alertSound10 = new Howl({
        src: [alert10],
        onend: onAlertStop
    });
    var alertSound5 = new Howl({
        src: [alert5],
        onend: onAlertStop
    });
    var alertSound2 = new Howl({
        src: [alert2],
        onend: onAlertStop
    });
    // const playerCallback2 = useCallback(node => {
    //     playerRef2.current = node;
    // },[playerRef2])
    // const playerCallback5 = useCallback(node => {
    //     playerRef5.current = node;
    // }, [playerRef5])
    // const playerCallback10 = useCallback(node => {
    //     playerRef10.current = node;
    // }, [playerRef10])
    // const playerCallback20 = useCallback(node => {
    //     playerRef20.current = node;
    // }, [playerRef20])
    function changeStates(){
        minShift.current = true;
        oldPlayerState.current = playerStat;
        if (playerStat === true && playerRef !== null){
            // setPlayerStat(false)
            playerRef.current.pause();
        }
        console.log(minShift.current);
    }
    function offShift(){
        minShift.current = false;
        console.log(minShift.current);
    }

    if (approchingEvent[0] != null && activeOghat === 'azan_sobh') {
        const approchingMin = (+approchingEvent[0][0] * 60) + (+approchingEvent[0][1])
        if (approchingMin === 20 && minShift.current === false) {
            changeStates();
            // playerRef20.current.play();
            alertSound20.play();
            onAlertPlay();
        } else if (approchingMin === 10 && minShift.current === false) {
            changeStates();
            // playerRef10.current.play();
            alertSound10.play();
            onAlertPlay();
        } else if (approchingMin === 5 && minShift.current === false) {
            changeStates();
            // playerRef5.current.play();
            alertSound5.play();
            onAlertPlay();
        } else if (approchingMin === 2 && minShift.current === false) {
            changeStates();
            // playerRef2.current.play();
            alertSound2.play();
            onAlertPlay();
        } else if ((approchingMin !== 20 && approchingMin !== 10 && approchingMin !== 5 && approchingMin !== 2) && minShift.current !== false) {
            offShift();
        }
    }
    function onAlertStop(){
        if (oldPlayerState.current === true) {
            setPlayerStat(true);
        }
    }
    function onAlertPlay(){
        minShift.current = true
        console.log('test');
    }
  

    return(
        <div>
            
        </div>
    )

}

export default Alert