import Date from "../components/Date"
import Clock from "../components/Clock"
import RegionSelector from "../components/RegionSelector"
import OghatShari from "../components/OghatShari"
import PlayerContainer from "../components/PlayerContainer"
import PlayerContainerImage from "../components/PlayerImageContainer"
import Banners from "../components/Banners"


function Home(){
    return(
        <>
        <div className="home-container">
            <div className="home-rightcontainer">
                <div className="box-container region-info">
                    <div className="clock-region-container">
                        <RegionSelector />
                        <Clock />
                    </div>
                    <Date />
                </div>
                <div className="box-container player-box">
                    <div className="player-img-container">
                        <PlayerContainerImage />
                    </div>
                    <div className="player-container">
                        <PlayerContainer />
                    </div>
                </div>
            </div>
            <div className="home-leftcontainer">
                <div className="box-container oghat-info">
                    <OghatShari />
                </div>
            </div>
        </div>
        <div className="ads-container">
            <Banners />
        </div>
        </>
        )
        
    }
    
    export default Home