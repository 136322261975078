
import { usePlayerStatContext } from "./PlayerStatProvider";

function Date(){
    const day_style = {
        flexGrow: 3,
    }
    const {time, ghamari, shamsi} = usePlayerStatContext();
    const currTime = time[0];
    const year = currTime.getFullYear();
    const month = currTime.getMonth();
    const date = currTime.getDate();
    const month_str = ['January', 'February','March','April','May','June','July',
    'August','September','October','November','December']
    const month_shamsi_str = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 
    'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند']
    const month_ghamari_str = ['محرم', 'صفر', 'ربیع الاول', 'ربیع الثانی', 'جمادی الاول', 'جمادی الثانیه',
    'رجب', 'شعبان', 'رمضان', 'شوال', 'ذیقعده','ذیحجه']
    const day_str = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    const day_fa_str = ['یک‌‌ شنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنج شنبه', 'جمعه','شنبه']
    const day_ar_str = ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس','الجمعه','السبت']
    const shamsi_str = shamsi != null ? `${day_fa_str[currTime.getDay()]} ${shamsi.split('/')[2]} ${month_shamsi_str[+shamsi.split('/')[1]-1]}` : '--';
    const gregorian_str = `${month_str[month]} ${date} ${day_str[currTime.getDay()]}`;
    const ghamari_str = ghamari != null ? `${day_ar_str[currTime.getDay()]} ${ghamari.split('-')[2]} ${month_ghamari_str[+ghamari.split('-')[1]-1]}` : '--';
    
    return(
        <div className="date-container" style={day_style}>
            <div className="jalali-date">
                <div className="header-date">
                    <h5>شمسی</h5>
                </div>
                <div className="date-data">
                    <span>{shamsi}</span>
                    <span className="gray-text">{shamsi_str}</span>
                </div>
            </div>
            <div className="ghamari-date">
                <div className="header-date">
                    <h5>قمری</h5>
                </div>
                <div className="date-data">
                    <span>{ghamari != null ? ghamari.replace(/-/g,'/') : '--'}</span>
                    <span className="gray-text">{ghamari_str}</span>
                </div>
            </div>
            <div className="gregorian-date">
                <div className="header-date">
                    <h5>میلادی</h5>
                </div>
                <div className="date-data">
                    <span>{year}/{(month + 1).toLocaleString({ minimumIntegerDigits: 2 })}/{date.toLocaleString({ minimumIntegerDigits: 2 })}</span>
                    <span className="gray-text">{gregorian_str}</span>
                </div>
            </div>
        </div>
    )

}

export default Date