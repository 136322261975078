import { useEffect, useState } from "react"
import shuffle from "../functions/shuffle"
import LoadingSpinner from "./LoadingSpinner"

function BannerEntry(props){
    const getBannerURL = props.urlApi //test
    const [Banner, setBanner] = useState(null)
    let output;
    
    useEffect(()=> {
        fetch(getBannerURL)
        .then((res)=>res.json())
        .then((json)=>{
            setBanner(shuffle(json));
            console.log(json);
        })
        .catch((error)=>console.log(error))
    }, [getBannerURL])

    if (Banner != null) {
        output = Banner.map((value, index) => {
            return props.url_link === 'true'
                ? <a href={`${value.url}`} className="banner-link" target="blank"><img className={index === 0 ? "banner-image-entry active" : "banner-image-entry"} width="100%" src={`${value.banner}`} alt={`${props.name}Banner${index}`} key={`${props.name}banner_key${index}`} /></a>
                : <img className={index === 0 ? "banner-image-entry active" : "banner-image-entry"} width="100%" src={`${value.banner}`} alt={`${props.name}Banner${index}`} key={`${props.name}banner_key${index}`} />
        })

    } else {
        output = <LoadingSpinner />
    }

    return (
       output
    )
}

export default BannerEntry