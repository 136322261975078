import { useCallback, useEffect } from "react";
import ClockDigital from "./ClockDigital"
import { usePlayerStatContext } from "./PlayerStatProvider"
import ClockAnalog from "./ClockAnalog";

function Clock(){
    const { time, ghamari, oghat ,activeOghat, approchingEvent, cookieRef} = usePlayerStatContext();
    const [currTime, setTime] = time;
    const [approchingOghat, setApprochingOghat] = approchingEvent;
    const month_ghamari_str = ['محرم', 'صفر', 'ربیع الاول', 'ربیع الثانی', 'جمادی الاول', 'جمادی الثانیه',
        'رجب', 'شعبان', 'رمضان', 'شوال', 'ذیقعده', 'ذیحجه']
    const approching_oghat_str = { sunrise: 'طلوع آفتاب', zohr: 'اذان ظهر', sunset: 'غروب خورشید', 
        maghreb: 'اذان مغرب', nime_shab: 'نیمه شب', azan_sobh : 'اذان صبح'};
    const ghamari_str = ghamari != null ? `${ghamari.split('-')[2]} ${month_ghamari_str[+ghamari.split('-')[1] - 1]} ${ghamari.split('-')[0]}` : '--'
    // console.log(oghat);
    const calApprochingOghat = useCallback(()=> {
        if(activeOghat != null){
            const time_str = oghat[activeOghat];
            const oghat_min = ((+time_str.split(':')[0] * 60) + (+time_str.split(':')[1]))
            const curtime_min = currTime.getHours() * 60 + currTime.getMinutes();
            let remain_min = null
            if(curtime_min < oghat_min){
                remain_min = oghat_min - curtime_min;
            } else {
                remain_min = (24*60 - curtime_min) + oghat_min
            }
            setApprochingOghat([
                (Math.floor(remain_min / 60)).toLocaleString('en-us', { minimumIntegerDigits: 2 }),
                (remain_min % 60).toLocaleString('en-us', { minimumIntegerDigits: 2 })
            ]);
        }
    },[currTime, oghat, activeOghat, setApprochingOghat])
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
            calApprochingOghat();
            if(cookieRef.current[1] !== 0){
                cookieRef.current[1] = cookieRef.current[1] -1
            }
        }, 1000)
        return ()=> 
        clearInterval(intervalId);
    },[setTime, calApprochingOghat, cookieRef])
    return(
        <div className="clock-container">
            <div className="analog-clock">
                <ClockAnalog time={currTime}/>
            </div>
            <div className="digital-clock">
                <ClockDigital time={currTime} />
            </div>
            <div className="ghamari-mobile">
                <strong><span className="header-date">{ghamari_str}</span></strong>
            </div>
            <div className="ghamari-mobile approching-oghat">
                <div className="digits-container" style={{textAlign: 'center'}}>
                    <span>{approchingOghat != null ? approchingOghat[0] : '--'}</span>
                    <span className={(currTime.getSeconds() % 2 === 0 || activeOghat == null) ? 'visibile-cl' : 'hidden-cl'}>:</span>
                    <span>{approchingOghat != null ? approchingOghat[1] : '--'}</span>
                </div>
                <div className="approch-oghat-str" style={{textAlign : 'center'}}>
                    <strong>
                    <span> تا </span>
                    <span>{activeOghat != null ? approching_oghat_str[activeOghat]: '--'}</span>
                    </strong>
                </div>
            </div>
        </div>
    )
}

export default Clock