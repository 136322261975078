import { useCallback, useEffect, useRef, useState } from "react";
import { usePlayerStatContext } from "./PlayerStatProvider"

function PlayerContainerProvider(){

    const {player, region, playerRef} = usePlayerStatContext()
    // const playerStat = player[0];
    const [playerStat, setPlayerStat] = player;
    const  city = region[1];
    const playPromise = useRef(null);
    const [audioTagStat, setAudioTagStat] = useState(playerStat);
    // const playerRef = useRef()
    const url = city[2];
    const playerCallback = useCallback(node =>{
        playerRef.current = node;
        if(node != null){
            if(playerStat){
                playPromise.current = playerRef.current.play();
            }
        }
    },[playerStat, playerRef])
    useEffect(()=>{
        if(playerStat === true){
            setAudioTagStat(true);
        } else {
            if (playerRef.current != null && playPromise.current !== undefined){
                playPromise.current.then(_=>{
                    playerRef.current.pause();
                })
                .catch(error=>{})
            }
            setAudioTagStat(false);
        }
    }, [playerStat, playerRef])
    function onPauseHit(){
        setPlayerStat(false)
    }
// return(
//     <>
//         {
//             url !== '' && audioTagStat
//         ?
//             <audio preload="none"  ref={playerCallback}>
//                 <source src={url}/>
//             </audio>
//         :
//             ''
//         }
//     </>
// )
    return (
        <>
            {
                url !== '' && audioTagStat
                    ?
                    <audio ref={playerCallback} onPause={onPauseHit}>
                        <source src={url} />
                    </audio>
                    :
                    ''
            }
        </>
    )
}

export default PlayerContainerProvider