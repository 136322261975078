import { useCallback, useEffect, useRef, useState } from "react"
import BannerEntry from "./BannerEntry"
import { usePlayerStatContext } from "./PlayerStatProvider"
function Banners(){
    const {region} = usePlayerStatContext()
    const city = region[1]
    const leftBannerRef = useRef(null)
    const rightBannerRef = useRef(null)
    const [leftBannerActive, setLeftBannerActive] = useState(null)
    const [rightBannerActive, setRightBannerActive] = useState(null)
    const [leftBannerNodes, setLeftBannerNodes] = useState([]);
    const [rightBannerNodes, setRightBannerNodes] = useState([]);
    const changeBanner = useCallback(() => {
        setLeftBannerNodes(leftBannerRef.current.querySelectorAll('img'));
        setRightBannerNodes(rightBannerRef.current.querySelectorAll('img'));
        if (leftBannerActive != null){
            if (leftBannerActive[1] < (leftBannerNodes.length-1)) {
                setLeftBannerActive([leftBannerActive[1],leftBannerActive[1]+1]);
            } else if (leftBannerActive != null) {
                setLeftBannerActive([leftBannerNodes.length - 1, 0])
            }
        }
        if (rightBannerActive != null){
            if (rightBannerActive[1] < (rightBannerNodes.length-1)) {
                setRightBannerActive([rightBannerActive[1],rightBannerActive[1]+1]);
            } else if (rightBannerActive != null) {
                setRightBannerActive([rightBannerNodes.length - 1, 0]);
            }
        }
        // console.log(leftBannerActive == null, leftBannerNodes, leftBannerRef.current.querySelectorAll('img'));
    }, [leftBannerActive, rightBannerActive, leftBannerNodes, rightBannerNodes])
    
    if (leftBannerActive == null && leftBannerNodes.length > 0){
        if (leftBannerNodes.length > 1){
            setLeftBannerActive([0, 1]);
        } else {
            setLeftBannerActive([0, 0]);
        }
        // setLeftBannerActive([leftBannerNodes.length - 1, 0]);
    }
    if (rightBannerActive == null && rightBannerNodes.length > 0) {
        if (rightBannerNodes.length > 1){
            setRightBannerActive([0, 1]);
        } else {
            setRightBannerActive([0, 0]);
        }
        // setRightBannerActive([rightBannerNodes.length - 1, 0]);
    }

    useEffect(()=> {
        const intervalId = setInterval(changeBanner,10000)
        // console.log('change');
        return ()=> clearInterval(intervalId)
    }, [changeBanner])
    useEffect(()=>{
        if(leftBannerActive != null){
            activateBanner(leftBannerNodes[leftBannerActive[1]])
            deactivateBanner(leftBannerNodes[leftBannerActive[0]])
        }
    },[leftBannerActive, leftBannerNodes])
    useEffect(() => {
        if (rightBannerActive != null){
            activateBanner(rightBannerNodes[rightBannerActive[1]])
            deactivateBanner(rightBannerNodes[rightBannerActive[0]])
        }
    }, [rightBannerActive, rightBannerNodes])
    
    function activateBanner(element){
        element.classList.add('active')
    }
    function deactivateBanner(element){
        element.classList.remove('active')
    }
    return(
        <>
        <div className="ad-entry-container">
        <div className="righ-ad ad-entry" ref={rightBannerRef}>
            {/* <BannerEntry name='right' url={`https://m.radioramezan.com/api/ads-site.php?city=${city[0]}`} /> */}
                <BannerEntry name='right' urlApi='https://m.radioramezan.com/api/hadis-web.php' url_link='false' />
        </div>
        </div>
        <div className="ad-entry-container">
        <div className="left-ad ad-entry" ref={leftBannerRef}>
            {/* <BannerEntry name='left' url='https://m.radioramezan.com/api/hadis.php'/> */}
                    <BannerEntry name='left' urlApi='https://m.radioramezan.com/api/ads-web.php'{...`?city=${city[0]}`} url_link='true'/>
        </div>
        </div>
        </>
        )
    }
    
    export default Banners