
import {Helmet} from 'react-helmet'
function Heading(props){
    console.log('hit1')
    return (
        <Helmet>
        <meta charSet='utf-8' />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>{props.title}</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
        <script src="https://www.google.com/recaptcha/api.js"></script>
        </Helmet>
        )
    }
    
    export default Heading