import React from "react";
import { useState, useEffect, useRef } from "react";
import moment from "moment-jalaali";
import Cookies from "js-cookie";

const PlayerStatContext = React.createContext();

const PlayerStatProvider = ({children}) => {
    let city_cookie = Cookies.get('set_city');
    let country_cookie = Cookies.get('set_country');
    if (city_cookie != null) {
        city_cookie = JSON.parse(city_cookie);
        city_cookie = city_cookie.length === 3 ? [+city_cookie[0], city_cookie[1], city_cookie[2]] : [];
    } else {
        city_cookie = []
    }
    if (country_cookie != null) {
        country_cookie = JSON.parse(country_cookie);
        country_cookie = country_cookie.length === 2 ? [+country_cookie[0], country_cookie[1]] : [];
    } else {
        country_cookie = []
    }
    const [playerStat, setPlayerStat] = useState(false);
    const [time, setTime] = useState(new Date());
    const [city, setCity] = useState(city_cookie.length === 3 ? city_cookie : [3, 'مونترال', '']);
    const [country, setCountry] = useState(country_cookie.length === 2 ? country_cookie : [1, 'کانادا']);
    const [countryList, setCountryList] = useState(null)
    const [cityList, setCityList] = useState(null)
    const [ghamariDate, setGhamariDate] = useState(null);
    const [shamsiDate, setShamsiDate] = useState(null);
    const [approchingOghat, setApprochingOghat] = useState(null);
    const [oghat, setOghat] = useState(null);
    const [activeOghat, setActiveOghat] = useState(null);
    const playerRef = useRef(null);
    const valid_cookie = useRef([country_cookie.length === 2 ? 1 : 0, city_cookie.length === 3 ? 1 : 0]);
    const country_id = country[0];
    const city_id = city[0];
    const year = time.getFullYear();
    const month = (time.getMonth()+1).toLocaleString({ minimumIntegerDigits: 2 });
    const date = time.getDate().toLocaleString({ minimumIntegerDigits: 2 });
    const currMin = time.getHours() * 60 + time.getMinutes();
    const getCountryURL = 'https://www.radioramezan.com/mobile/api/countries.php';
    const getCityURL = 'https://www.radioramezan.com/mobile/api/cities.php?country_id=';

    function returnMinutes(time) {
        const sep_time = time.split(':')
        return (+sep_time[0] * 60) + (+sep_time[1])
    }

    useEffect(() => {
        setOghat(null)
        setActiveOghat(null)
        setApprochingOghat(null)
        if (city_id != null) {
            const getOghatURL = `https://m.radioramezan.com/api/owghat.php?city=${city_id}&date=${year + '-' + month + '-' + date}&method=0`
            // console.log(getOghatURL);
            fetch(getOghatURL)
                .then((res) => res.json())
                .then((json) => {
                    setOghat(json);
                    // console.log(json);
                })
        }
    }, [city_id, date, month, year])
    useEffect(() => {
        if (oghat != null) {
            if (returnMinutes(oghat.azan_sobh) <= currMin && returnMinutes(oghat.sunrise) >= currMin) {
                setActiveOghat('sunrise');
            } else if (returnMinutes(oghat.sunrise) <= currMin && returnMinutes(oghat.zohr) >= currMin) {
                setActiveOghat('zohr');
            } else if (returnMinutes(oghat.zohr) <= currMin && returnMinutes(oghat.sunset) >= currMin) {
                setActiveOghat('sunset');
            } else if (returnMinutes(oghat.sunset) <= currMin && returnMinutes(oghat.maghreb) >= currMin) {
                setActiveOghat('maghreb');
            } else if (returnMinutes(oghat.maghreb) <= currMin && returnMinutes(oghat.nime_shab) >= currMin) {
                setActiveOghat('nime_shab');
            } else {
                setActiveOghat('azan_sobh');
            }
        } else {
            setActiveOghat(null);
        }
    }, [oghat, currMin])

    useEffect(() => {
        fetch(getCountryURL)
        .then((res) => res.json())
        .then((json) => {
            setCountryList(json);
        })
        .catch(error => {
            console.log(error);
            // alert('مشکلی در ارتباط به وجود آمده لطفا صفحه را مجددا لود نمایید.')
        })
    }, [])
    useEffect(()=>{
        fetch(getCityURL + country_id)
        .then((res) => res.json())
        .then((json)=>{
            if (json.length > 0){
                setCityList(json);
                if(valid_cookie.current[1] === 0){
                    setCity([json[0].city_id, json[0].city_name_fa, json[0].url]);
                    Cookies.set('set_city', JSON.stringify([json[0].city_id, json[0].city_name_fa, json[0].url]));
                }
            } else {
                setCityList([{city_name_fa:'شهری تعریف نشده است'}])
                setCity([null,''])
            }
        })
    },[country_id])
    useEffect(()=> {
        const city_id = city[0] === null ? 3 : city;
        setShamsiDate(moment(`${year}/${month}/${date}`, 'YYYY/MM/DD').format('jYYYY/jMM/jDD'))
        const getGhamariURL = `https://m.radioramezan.com/api/ghamari.php?city=${city_id}&date=${year + '-' + month + '-' + date}`;
        fetch(getGhamariURL)
        .then((res) => res.json())
        .then((res) => {
            setGhamariDate(res[0].ghamari)
        })
        .catch(error => {
            console.log(error);
            // alert('مشکلی در ارتباط به وجود آمده لطفا صفحه را مجددا لود نمایید.')
        })
    },[city,date,month,year])
    return (
        <PlayerStatContext.Provider value={{ 
            player: [playerStat, setPlayerStat], 
            time: [time, setTime], 
            ghamari: ghamariDate,
            shamsi: shamsiDate,
            region:[country,city], 
            regionList:[countryList,cityList],
            setRegion: [setCountry,setCity] ,
            activeOghat: activeOghat,
            oghat: oghat,
            playerRef : playerRef,
            cookieRef: valid_cookie,
            approchingEvent:[approchingOghat, setApprochingOghat],}}
            >
            {children}
            </PlayerStatContext.Provider>
            )
        }
        
        export const usePlayerStatContext = () => React.useContext(PlayerStatContext);
        
        export default PlayerStatProvider