import { useMatch } from "react-router-dom"
import { usePlayerStatContext } from "./PlayerStatProvider";

function PlayerControlFooter(){
    const {player, region} = usePlayerStatContext()
    const [playerStat, setPlayerStat] = player;
    const [country, city] = region;
    const matchUrl = useMatch('/');
    const controlShow = true ? matchUrl == null : false;

    function togglePlayStat(e){
        setPlayerStat(!playerStat);
        e.preventDefault();
    }
    if (controlShow){
        return (
            <>
                <div className="player-control-container active">
                    <a onClick={togglePlayStat} href="#/">
                        <svg width="45" height="45" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg" className={playerStat ? 'hidden_bt' : ''}>
                            <path d="M45 7.85693C24.4972 7.85693 7.85718 24.4969 7.85718 44.9998C7.85718 65.5026 24.4972 82.1426 45 82.1426C65.5029 82.1426 82.1429 65.5026 82.1429 44.9998C82.1429 24.4969 65.5029 7.85693 45 7.85693ZM54.88 51.4255L50.1257 54.1741L45.3715 56.9226C39.2429 60.4512 34.2286 57.5541 34.2286 50.4969V44.9998V39.5026C34.2286 32.4084 39.2429 29.5484 45.3715 33.0769L50.1257 35.8255L54.88 38.5741C61.0086 42.1026 61.0086 47.8969 54.88 51.4255Z" fill="#822C61" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 200 200" fill="none" className={playerStat ? '' : 'hidden_bt'}>
                            <path d="M99.7498 16.6666C53.7498 16.6666 16.4165 54 16.4165 100C16.4165 146 53.7498 183.333 99.7498 183.333C145.75 183.333 183.083 146 183.083 100C183.083 54 145.833 16.6666 99.7498 16.6666ZM89.3332 125.25C89.3332 129.25 87.6665 130.833 83.4165 130.833H72.5832C68.3332 130.833 66.6665 129.25 66.6665 125.25V74.75C66.6665 70.75 68.3332 69.1666 72.5832 69.1666H83.3332C87.5832 69.1666 89.2498 70.75 89.2498 74.75V125.25H89.3332ZM133.333 125.25C133.333 129.25 131.667 130.833 127.417 130.833H116.667C112.417 130.833 110.75 129.25 110.75 125.25V74.75C110.75 70.75 112.417 69.1666 116.667 69.1666H127.417C131.667 69.1666 133.333 70.75 133.333 74.75V125.25Z" fill="#822C61" />
                        </svg>
                    </a>
                </div>
                <div className={"equalizer-footer-container" + (playerStat ? " active" : "")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 40" width="50" height="40">
                        <rect x="0" y="39" width="70" height="1" fill="rgba(0,0,0,.5)" />
                        <rect id="eq_bar1" x="2" width="15" height="1" transform="translate(0 40) scale(1 -1) " fill="rgba(0,0,0,.5)" />
                        <rect id="eq_bar2" x="19" width="15" height="1" transform="translate(0 40) scale(1 -1) " fill="rgba(0,0,0,.5)" />
                        <rect id="eq_bar3" x="36" width="15" height="1" transform="translate(0 40) scale(1 -1) " fill="rgba(0,0,0,.5)" />
                        <rect id="eq_bar4" x="53" width="15" height="1" transform="translate(0 40) scale(1 -1) " fill="rgba(0,0,0,.5)" />
                    </svg>
                </div>
                <div className="country-city-container">
                    <span>{`به افق شهر ${city[1]} ${country[1]}`}</span>
                </div>
            </>
        )
    } else{
        return (<div className="player-control-container"></div>)
    }
    

}

export default PlayerControlFooter