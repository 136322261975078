import LoadingSpinner from "./LoadingSpinner"
// import { usePlayerStatContext } from "./PlayerStatProvider"

function OghatList(props){
    // const {time, approchingEvent, activeOghat} = usePlayerStatContext()
    // const currTime = time[0];
    // const approchingOghat = approchingEvent[0];
    // const approching_oghat_str = {
    //     sunrise: 'طلوع آفتاب', zohr: 'اذان ظهر', sunset: 'غروب خورشید',
    //     maghreb: 'اذان مغرب', nime_shab: 'نیمه شب', azan_sobh: 'اذان صبح'
    // };


    
    if (props.oghat != null && props.cityList != null){
        return(
            <>
            <div className={`azan-sobh-time oghat-time-entry ${props.activeOghat === 'azan_sobh' ? ' active' : ''}`}>
                <span className="oghat-icon-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M20.61 10.83C19.84 9.90996 18.85 9.30996 17.7 9.04996C17.44 7.88996 16.99 6.88996 16.33 6.05996C16.31 6.02996 16.29 5.99996 16.27 5.97996C14.07 3.31996 10.47 3.32996 8.04001 4.70996C5.96001 5.89996 4.19001 8.40996 5.12001 11.99C2.99001 12.51 2.01001 14.37 2.01001 16.05C2.01001 17.93 3.24001 20.04 5.98001 20.24H16.32C16.33 20.24 16.35 20.24 16.36 20.24C17.78 20.24 19.14 19.71 20.19 18.75C22.7 16.53 22.37 12.91 20.61 10.83Z" fill="#822C61" />
                        <path d="M21.76 8.73977C21.76 9.07977 21.72 9.40976 21.62 9.72976C20.89 8.87977 19.96 8.24977 18.93 7.85977C18.61 6.85977 18.1499 5.96977 17.5499 5.18977C17.5199 5.14977 17.49 5.10977 17.45 5.05977C17.64 5.01977 17.84 5.00977 18.03 5.00977C20.09 5.00977 21.76 6.67977 21.76 8.73977Z" fill="#822C61" />
                    </svg>
                </span>
                <span className="oghat-loaded-title">اذان صبح</span><span className="oghat-loaded-time"> {props.oghat.azan_sobh}</span>
            </div>
            <div className={`sunrise-time oghat-time-entry ${props.activeOghat === 'sunrise' ? ' active' : ''}`}>
                <span className="oghat-icon-container">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.48 12.25C18.76 12.25 19 12.01 18.98 11.73C18.71 8.1 15.69 5.25 12 5.25C8.30997 5.25 5.28997 8.1 5.01997 11.73C4.99997 12.01 5.23997 12.25 5.51997 12.25H18.48Z" fill="#822C61" />
                        <path d="M22 13H21.92C21.37 13 20.92 12.55 20.92 12C20.92 11.45 21.37 11 21.92 11C22.47 11 22.96 11.45 22.96 12C22.96 12.55 22.55 13 22 13ZM2.08 13H2C1.45 13 1 12.55 1 12C1 11.45 1.45 11 2 11C2.55 11 3.04 11.45 3.04 12C3.04 12.55 2.63 13 2.08 13ZM19.01 5.99C18.75 5.99 18.5 5.89 18.3 5.7C17.91 5.31 17.91 4.68 18.3 4.29L18.43 4.16C18.82 3.77 19.45 3.77 19.84 4.16C20.23 4.55 20.23 5.18 19.84 5.57L19.71 5.7C19.52 5.89 19.27 5.99 19.01 5.99ZM4.99 5.99C4.73 5.99 4.48 5.89 4.28 5.7L4.15 5.56C3.76 5.17 3.76 4.54 4.15 4.15C4.54 3.76 5.17 3.76 5.56 4.15L5.69 4.28C6.08 4.67 6.08 5.3 5.69 5.69C5.5 5.89 5.24 5.99 4.99 5.99ZM12 3.04C11.45 3.04 11 2.63 11 2.08V2C11 1.45 11.45 1 12 1C12.55 1 13 1.45 13 2C13 2.55 12.55 3.04 12 3.04Z" fill="#822C61" />
                        <path d="M20 15.75H4C3.59 15.75 3.25 15.41 3.25 15C3.25 14.59 3.59 14.25 4 14.25H20C20.41 14.25 20.75 14.59 20.75 15C20.75 15.41 20.41 15.75 20 15.75Z" fill="#822C61" />
                        <path d="M18 18.75H6C5.59 18.75 5.25 18.41 5.25 18C5.25 17.59 5.59 17.25 6 17.25H18C18.41 17.25 18.75 17.59 18.75 18C18.75 18.41 18.41 18.75 18 18.75Z" fill="#822C61" />
                        <path d="M15 21.75H9C8.59 21.75 8.25 21.41 8.25 21C8.25 20.59 8.59 20.25 9 20.25H15C15.41 20.25 15.75 20.59 15.75 21C15.75 21.41 15.41 21.75 15 21.75Z" fill="#822C61" />
                    </svg>
                </span>
                <span className="oghat-loaded-title">طلوع آفتاب</span><span className="oghat-loaded-time"> {props.oghat.sunrise}</span>
            </div>
            <div className={`zohr-time oghat-time-entry ${props.activeOghat === 'zohr' ? ' active' : ''}`}>
                <span className="oghat-icon-container">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 19.0001C15.866 19.0001 19 15.8661 19 12C19 8.13402 15.866 5 12 5C8.13401 5 5 8.13402 5 12C5 15.8661 8.13401 19.0001 12 19.0001Z" fill="#822C61" />
                        <path d="M12 22.9601C11.45 22.9601 11 22.5501 11 22.0001V21.9201C11 21.3701 11.45 20.9201 12 20.9201C12.55 20.9201 13 21.3701 13 21.9201C13 22.4701 12.55 22.9601 12 22.9601ZM19.14 20.1401C18.88 20.1401 18.63 20.0401 18.43 19.8501L18.3 19.7201C17.91 19.3301 17.91 18.7001 18.3 18.3101C18.69 17.9201 19.32 17.9201 19.71 18.3101L19.84 18.4401C20.23 18.8301 20.23 19.4601 19.84 19.8501C19.65 20.0401 19.4 20.1401 19.14 20.1401ZM4.86 20.1401C4.6 20.1401 4.35 20.0401 4.15 19.8501C3.76 19.4601 3.76 18.8301 4.15 18.4401L4.28 18.3101C4.67 17.9201 5.3 17.9201 5.69 18.3101C6.08 18.7001 6.08 19.3301 5.69 19.7201L5.56 19.8501C5.37 20.0401 5.11 20.1401 4.86 20.1401ZM22 13.0001H21.92C21.37 13.0001 20.92 12.5501 20.92 12.0001C20.92 11.4501 21.37 11.0001 21.92 11.0001C22.47 11.0001 22.96 11.4501 22.96 12.0001C22.96 12.5501 22.55 13.0001 22 13.0001ZM2.08 13.0001H2C1.45 13.0001 1 12.5501 1 12.0001C1 11.4501 1.45 11.0001 2 11.0001C2.55 11.0001 3.04 11.4501 3.04 12.0001C3.04 12.5501 2.63 13.0001 2.08 13.0001ZM19.01 5.99003C18.75 5.99003 18.5 5.89003 18.3 5.70002C17.91 5.31002 17.91 4.68002 18.3 4.29002L18.43 4.16002C18.82 3.77001 19.45 3.77001 19.84 4.16002C20.23 4.55002 20.23 5.18002 19.84 5.57002L19.71 5.70002C19.52 5.89003 19.27 5.99003 19.01 5.99003ZM4.99 5.99003C4.73 5.99003 4.48 5.89003 4.28 5.70002L4.15 5.56002C3.76 5.17002 3.76 4.54002 4.15 4.15002C4.54 3.76001 5.17 3.76001 5.56 4.15002L5.69 4.28002C6.08 4.67002 6.08 5.30002 5.69 5.69002C5.5 5.89003 5.24 5.99003 4.99 5.99003ZM12 3.04001C11.45 3.04001 11 2.63001 11 2.08001V2.00001C11 1.45 11.45 1 12 1C12.55 1 13 1.45 13 2.00001C13 2.55001 12.55 3.04001 12 3.04001Z" fill="#822C61" />
                    </svg>
                </span>
                <span className="oghat-loaded-title">اذان ظهر</span><span className="oghat-loaded-time"> {props.oghat.zohr}</span>
            </div>
            <div className={`sunset-time oghat-time-entry ${props.activeOghat === 'sunset' ? ' active' : ''}`}>
                <span className="oghat-icon-container">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.27 8.75977C21.27 9.77977 20.8 10.6998 20.04 11.2998C19.49 11.7498 18.78 12.0198 18.01 12.0198C16.22 12.0198 14.76 10.5598 14.76 8.76977C14.76 7.87977 15.12 7.06977 15.72 6.47977V6.46977C16.31 5.87977 17.12 5.50977 18.01 5.50977C19.81 5.50977 21.27 6.96977 21.27 8.75977Z" fill="#822C61" />
                        <path d="M20.18 18.7298C19.13 19.6898 17.78 20.2198 16.35 20.2198H5.97001C3.23001 20.0198 2.01001 17.9098 2.01001 16.0298C2.01001 14.3498 2.98001 12.4898 5.11001 11.9698C4.18001 8.38985 5.96001 5.88985 8.04001 4.69985C10.1 3.52985 13 3.33985 15.18 4.94985C14.99 5.08985 14.82 5.23985 14.65 5.40985L14.22 5.85985V5.91985C13.6 6.72985 13.26 7.71985 13.26 8.75985C13.26 11.3798 15.4 13.5098 18.02 13.5098C19.1 13.5098 20.15 13.1398 20.97 12.4698C21.12 12.3498 21.27 12.2198 21.4 12.0798C22.39 14.1598 22.25 16.9198 20.18 18.7298Z" fill="#822C61" />
                    </svg>
                </span>
                <span className="oghat-loaded-title">غروب خورشید</span><span className="oghat-loaded-time"> {props.oghat.sunset}</span>
            </div>
            <div className={`maghreb-time oghat-time-entry ${props.activeOghat === 'maghreb' ? ' active' : ''}`}>
                <span className="oghat-icon-container">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.47005 15.9304C2.63004 15.6604 3.08005 15.2404 4.20005 15.4404C4.82005 15.5504 5.45005 15.6004 6.08005 15.5704C8.41005 15.4704 10.52 14.4004 11.99 12.7504C13.29 11.3004 14.09 9.4104 14.1 7.37039C14.1 6.23038 13.88 5.13038 13.43 4.09037C12.99 3.08037 13.3 2.55036 13.52 2.33036C13.75 2.10036 14.29 1.78036 15.35 2.22036C19.44 3.94037 21.97 8.04039 21.67 12.4304C21.37 16.5604 18.47 20.0905 14.63 21.4205C13.71 21.7405 12.74 21.9305 11.74 21.9705C11.58 21.9805 11.42 21.9905 11.26 21.9905C7.91005 21.9905 4.77005 20.4105 2.79005 17.7204C2.12005 16.7904 2.30005 16.2004 2.47005 15.9304Z" fill="#822C61" />
                    </svg>
                </span>
                <span className="oghat-loaded-title">اذان مغرب</span><span className="oghat-loaded-time"> {props.oghat.maghreb}</span>
            </div>
            <div className={`nimeshab-time oghat-time-entry ${props.activeOghat === 'nime_shab' ? ' active' : ''}`}>
                <span className="oghat-icon-container">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.1798 18.7308C19.1298 19.6908 17.7798 20.2208 16.3498 20.2208H5.96977C3.22977 20.0208 2.00977 17.9108 2.00977 16.0308C2.00977 14.3508 2.97977 12.4908 5.10977 11.9708C4.17977 8.39084 5.95977 5.89082 8.03977 4.70082C10.0998 3.53082 12.9998 3.34082 15.1798 4.95082C14.9898 5.09082 14.8198 5.24082 14.6498 5.41082L14.2198 5.86083V5.92083C13.5998 6.73082 13.2598 7.72083 13.2598 8.76083C13.2598 11.3808 15.3998 13.5108 18.0198 13.5108C19.0998 13.5108 20.1498 13.1408 20.9698 12.4708C21.1198 12.3508 21.2698 12.2208 21.3998 12.0808C22.3898 14.1608 22.2498 16.9208 20.1798 18.7308Z" fill="#822C61" />
                        <path d="M21.1271 10.1634C21.3358 9.59617 21.4605 8.99667 21.4873 8.37772C21.4931 8.29091 21.5 8.18553 21.5 8.07809C21.5 6.00697 20.4663 4.07414 18.7222 2.86047L18.7181 2.85763C18.3809 2.62798 18.0667 2.51808 17.7785 2.50206C17.4912 2.48608 17.2664 2.56559 17.1119 2.65633C16.9532 2.7466 16.7691 2.90822 16.6518 3.17392C16.5337 3.44148 16.5024 3.77097 16.5765 4.16364C16.6363 4.4823 16.6623 4.80008 16.6466 5.11299L16.6465 5.11547C16.5933 6.28708 16.0238 7.35797 15.1309 8.10998L15.1298 8.11099C14.345 8.77621 13.3232 9.18458 12.2222 9.18995C11.6003 9.18981 11.0051 9.07655 10.4441 8.84712C10.0739 8.69476 9.74829 8.65495 9.46674 8.70144C9.18339 8.74824 8.9824 8.87631 8.85427 8.99744C8.72567 9.11903 8.57882 9.31774 8.52289 9.6092C8.46729 9.89891 8.5112 10.2298 8.67392 10.6004L8.6749 10.6026C9.79733 13.126 12.461 14.6683 15.2932 14.4853L15.2952 14.4852C17.9712 14.3014 20.2607 12.5283 21.1271 10.1634Z" fill="#822C61" stroke="white" strokeWidth="1.5" />
                    </svg>
                </span>
                <span className="oghat-loaded-title">نیمه شب</span><span className="oghat-loaded-time"> {props.oghat.nime_shab}</span>
            </div>
            </>
            )
        } else{
            return (
                <LoadingSpinner />
                )
            }
        }
        
        export default OghatList