
import { useEffect, useRef, useState } from 'react'
import radio_logo from '../static/logo/radio_logo.png'
import share_link from '../static/txt/share_txt.txt'
import { NavLink } from 'react-router-dom'
import CopyNotif from './CopyNotif'

function Nav(){
    const logo_style = {
        height : "3em"
    }
    const side_flex = {
        flexGrow : '4',
        textAlign: 'center',
        flexBasis: '5em',
    }
    const [menuItemsState, setMenuItemState] = useState(false)
    const [activeCopy, setActiveCopy] = useState(null)
    const text_share = useRef(null);
    const intervalId = useRef(null);
    const activeLinkStat = ({ isActive, isPending }) => isActive ? 'active' : isPending ? 'pending' : ''
    useEffect(()=>{
        if(activeCopy === true){
            intervalId.current = setInterval(()=>{
                clearInterval(intervalId.current);
                setActiveCopy(false)
            },1500);
            console.log('copied');
        }
    },[activeCopy, setActiveCopy])
    useEffect(()=> {
        fetch(share_link)
        .then((res) => res.text())
        .then((res_txt) => {
            text_share.current = res_txt;
        })
        .catch((error) =>console.log('Read Text Error', error));
    },[])
    function toggleMenu(){
        setMenuItemState(!menuItemsState)
    }
    function shareLink(e){
        // console.log(text_share.current);
        if (navigator.share) {
            navigator.share({
                title: 'دعوت از دوستان',
                text: text_share.current,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            copyTextToClipboard(text_share.current)
        }
        e.preventDefault();
    }
    async function copyTextToClipboard(text) {
        await navigator.clipboard.writeText(text)
            .then(() => {
                // async_msg('کپی شد!', 'success');
                // console.log('success copy');
                setActiveCopy(true);
            })
            .catch((err) => {
                // async_msg('خطا کپی!', 'error');
                console.log('error copy',err);
            });
    }
    return (
        <>
        <CopyNotif active={activeCopy}/>
        <nav className="main-nav">
        <ul>
        <div className={'bread-comb-container' + (menuItemsState ? ' active' : '')} onClick={toggleMenu}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="#EDEDED" />
                <path id="brc1" d="M29 15.75H11C10.59 15.75 10.25 15.41 10.25 15C10.25 14.59 10.59 14.25 11 14.25H29C29.41 14.25 29.75 14.59 29.75 15C29.75 15.41 29.41 15.75 29 15.75Z" fill="#757575" />
                <path id="brc2" d="M29 20.75H11C10.59 20.75 10.25 20.41 10.25 20C10.25 19.59 10.59 19.25 11 19.25H29C29.41 19.25 29.75 19.59 29.75 20C29.75 20.41 29.41 20.75 29 20.75Z" fill="#757575" />
                <path id="brc3" d="M29 25.75H11C10.59 25.75 10.25 25.41 10.25 25C10.25 24.59 10.59 24.25 11 24.25H29C29.41 24.25 29.75 24.59 29.75 25C29.75 25.41 29.41 25.75 29 25.75Z" fill="#757575" />
            </svg>
        </div>
        <div style={side_flex}><div className="active-bar"></div><img src={radio_logo} style={logo_style} alt='Radio Ramezan Logo'></img></div>
        <div className={'menu-items-container' + (menuItemsState?' active':'')}>
        <li className="active"><div className="active-bar"></div>
        <NavLink to='/' className={activeLinkStat}>
        <h4>خانه</h4>
        </NavLink>
        </li>
        <li><div className="active-bar"></div><a href="#/" onClick={shareLink}>
        <h4>معرفی به دیگران</h4>
        </a></li>
        <li><div className="active-bar"></div>
        <NavLink to="/contact-us" className={activeLinkStat}>
        <h4>ارتباط با ما</h4>
        </NavLink></li>
        <li><div className="active-bar"></div>
        <NavLink to="/about-us" className={activeLinkStat}>
        <h4>درباره ما</h4>
        </NavLink></li>
        <li>
            <div className='active-bar'></div>
                <a href='http://onelink.to/radio_ramezan'>
                <span>دریافت اپلیکیشن</span>
                <span>
                    <svg style={{ verticalAlign: 'middle' }} width="20" height="20" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M76.695 29.3549C76.326 29.5859 67.542 34.4609 67.542 45.2699C67.956 57.5969 78.627 61.9199 78.81 61.9199C78.627 62.1509 77.199 67.8089 72.969 73.7399C69.612 78.8489 65.886 83.9999 60.228 83.9999C54.846 83.9999 52.914 80.5949 46.704 80.5949C40.035 80.5949 38.148 83.9999 33.042 83.9999C27.384 83.9999 23.382 78.5729 19.842 73.5119C15.243 66.8879 11.334 56.4929 11.196 46.5119C11.103 41.2229 12.117 36.0239 14.691 31.6079C18.324 25.4429 24.81 21.2579 31.893 21.1199C37.32 20.9369 42.15 24.8459 45.462 24.8459C48.636 24.8459 54.57 21.1199 61.284 21.1199C64.182 21.1229 71.91 21.9959 76.695 29.3549ZM45.003 20.0639C44.037 15.2339 46.704 10.4039 49.188 7.32287C52.362 3.59687 57.375 1.06787 61.698 1.06787C61.974 5.89787 60.225 10.6349 57.099 14.0849C54.294 17.8109 49.464 20.6159 45.003 20.0639Z" fill="black" />
                    </svg>
                </span>
                <span style={{color:'black'}}>|</span>
                <span>
                    <svg style={{ verticalAlign: 'middle' }} width="20" height="20" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.0332 4.54675C28.761 4.553 28.4957 4.6332 28.2656 4.77875C28.0356 4.9243 27.8495 5.12971 27.7272 5.37296C27.605 5.61621 27.5513 5.88814 27.5718 6.1596C27.5923 6.43107 27.6863 6.69182 27.8438 6.91393L32.2793 13.3241C28.0205 16.5878 25.0376 21.4361 24.2402 26.9999H65.7598C64.9624 21.4361 61.9795 16.5878 57.7207 13.3241L62.1562 6.91393C62.3159 6.68858 62.4102 6.42354 62.4288 6.14801C62.4474 5.87248 62.3896 5.59715 62.2618 5.35237C62.1339 5.10758 61.941 4.90284 61.7042 4.76069C61.4675 4.61854 61.1961 4.54451 60.9199 4.54675C60.6765 4.54899 60.4373 4.61044 60.2229 4.72579C60.0085 4.84115 59.8254 5.00694 59.6895 5.20886L55.2188 11.6776C52.1948 9.98307 48.7164 8.99987 45 8.99987C41.2836 8.99987 37.8052 9.98307 34.7812 11.6776L30.3105 5.20886C30.1702 5.0003 29.9796 4.83037 29.7564 4.71468C29.5332 4.59899 29.2845 4.54124 29.0332 4.54675ZM36 14.9999C37.656 14.9999 39 16.3439 39 17.9999C39 19.6559 37.656 20.9999 36 20.9999C34.344 20.9999 33 19.6559 33 17.9999C33 16.3439 34.344 14.9999 36 14.9999ZM54 14.9999C55.656 14.9999 57 16.3439 57 17.9999C57 19.6559 55.656 20.9999 54 20.9999C52.344 20.9999 51 19.6559 51 17.9999C51 16.3439 52.344 14.9999 54 14.9999ZM15 32.9999C13.344 32.9999 12 34.3439 12 35.9999V59.9999C12 61.6559 13.344 62.9999 15 62.9999C16.656 62.9999 18 61.6559 18 59.9999V35.9999C18 34.3439 16.656 32.9999 15 32.9999ZM24 32.9999V62.9999C24 66.3149 26.685 68.9999 30 68.9999V79.4999C30 81.9839 32.016 83.9999 34.5 83.9999C36.984 83.9999 39 81.9839 39 79.4999V68.9999H51V79.4999C51 81.9839 53.016 83.9999 55.5 83.9999C57.984 83.9999 60 81.9839 60 79.4999V68.9999C63.315 68.9999 66 66.3149 66 62.9999V32.9999H24ZM75 32.9999C73.344 32.9999 72 34.3439 72 35.9999V59.9999C72 61.6559 73.344 62.9999 75 62.9999C76.656 62.9999 78 61.6559 78 59.9999V35.9999C78 34.3439 76.656 32.9999 75 32.9999Z" fill="black" />
                    </svg>
                </span>
                
            </a>
        </li>
        </div>
                <div style={side_flex} className='donation-btn-container'><div className="active-bar"></div><a className='donation-btn' rel="noreferrer" target='_blank' href='https://www.radioramezan.com/arzi.php'>
        <svg className='money-desktop' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8902 18.2197H6.10019C5.58019 18.2197 5.09018 18.1698 4.65018 18.0698C4.42018 18.0398 4.14019 17.9597 3.85019 17.8497C2.52019 17.3497 0.950195 16.1198 0.950195 13.0598V7.90973C0.950195 4.63973 2.83019 2.75977 6.10019 2.75977H14.8902C17.6502 2.75977 19.4302 4.07974 19.9102 6.47974C20.0002 6.91974 20.0402 7.38973 20.0402 7.90973V13.0598C20.0402 16.3498 18.1702 18.2197 14.8902 18.2197ZM6.11017 4.27979C3.65017 4.27979 2.46017 5.46975 2.46017 7.92975V13.0798C2.46017 14.8698 3.09019 15.9698 4.38019 16.4598C4.58019 16.5298 4.7702 16.5797 4.9502 16.6097C5.3302 16.6897 5.70017 16.7297 6.11017 16.7297H14.9002C17.3602 16.7297 18.5502 15.5398 18.5502 13.0798V7.92975C18.5502 7.50975 18.5202 7.13974 18.4502 6.79974C18.1102 5.09974 16.9502 4.27979 14.9002 4.27979H6.11017Z" fill="white" />
            <path d="M17.8901 21.2203H9.10009C8.25009 21.2203 7.49008 21.1003 6.84008 20.8503C5.37008 20.3003 4.41007 19.1403 4.08007 17.4803C4.03007 17.2303 4.11009 16.9703 4.29009 16.8003C4.47009 16.6203 4.7301 16.5503 4.9801 16.6103C5.3101 16.6803 5.68009 16.7203 6.10009 16.7203H14.8901C17.3501 16.7203 18.5401 15.5304 18.5401 13.0704V7.92033C18.5401 7.50033 18.5101 7.13033 18.4401 6.79033C18.3901 6.54033 18.4701 6.29033 18.6401 6.11033C18.8201 5.93033 19.0701 5.85032 19.3201 5.91032C21.7201 6.40032 23.0401 8.18033 23.0401 10.9203V16.0704C23.0401 19.3504 21.1701 21.2203 17.8901 21.2203ZM5.9201 18.2203C6.2401 18.8003 6.72009 19.2104 7.38009 19.4504C7.86009 19.6304 8.44007 19.7203 9.11007 19.7203H17.9001C20.3601 19.7203 21.5501 18.5304 21.5501 16.0704V10.9203C21.5501 9.34033 21.0601 8.29034 20.0501 7.74034C20.0501 7.80034 20.0501 7.86033 20.0501 7.92033V13.0704C20.0501 16.3404 18.1701 18.2203 14.9001 18.2203H6.11007C6.04007 18.2203 5.9801 18.2203 5.9201 18.2203Z" fill="white" />
            <path d="M10.4999 13.8904C8.62988 13.8904 7.10986 12.3704 7.10986 10.5004C7.10986 8.63037 8.62988 7.11035 10.4999 7.11035C12.3699 7.11035 13.8899 8.63037 13.8899 10.5004C13.8899 12.3704 12.3699 13.8904 10.4999 13.8904ZM10.4999 8.61035C9.45988 8.61035 8.60986 9.46037 8.60986 10.5004C8.60986 11.5404 9.45988 12.3904 10.4999 12.3904C11.5399 12.3904 12.3899 11.5404 12.3899 10.5004C12.3899 9.46037 11.5399 8.61035 10.4999 8.61035Z" fill="white" />
            <path d="M4.78027 13.4498C4.37027 13.4498 4.03027 13.1098 4.03027 12.6998V8.2998C4.03027 7.8898 4.37027 7.5498 4.78027 7.5498C5.19027 7.5498 5.53027 7.8898 5.53027 8.2998V12.6998C5.53027 13.1098 5.20027 13.4498 4.78027 13.4498Z" fill="white" />
            <path d="M16.21 13.4498C15.8 13.4498 15.46 13.1098 15.46 12.6998V8.2998C15.46 7.8898 15.8 7.5498 16.21 7.5498C16.62 7.5498 16.96 7.8898 16.96 8.2998V12.6998C16.96 13.1098 16.63 13.4498 16.21 13.4498Z" fill="white" />
        </svg>
        <svg className='money-mobile' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#822C61" fillOpacity="0.1" />
            <path d="M22.8902 26.22H14.1002C13.5802 26.22 13.0902 26.17 12.6502 26.07C12.4202 26.04 12.1402 25.96 11.8502 25.85C10.5202 25.35 8.9502 24.12 8.9502 21.06V15.91C8.9502 12.64 10.8302 10.76 14.1002 10.76H22.8902C25.6502 10.76 27.4302 12.08 27.9102 14.48C28.0002 14.92 28.0402 15.39 28.0402 15.91V21.06C28.0402 24.35 26.1702 26.22 22.8902 26.22ZM14.1102 12.28C11.6502 12.28 10.4602 13.47 10.4602 15.93V21.08C10.4602 22.87 11.0902 23.97 12.3802 24.46C12.5802 24.53 12.7702 24.58 12.9502 24.61C13.3302 24.69 13.7002 24.73 14.1102 24.73H22.9002C25.3602 24.73 26.5502 23.54 26.5502 21.08V15.93C26.5502 15.51 26.5202 15.14 26.4502 14.8C26.1102 13.1 24.9502 12.28 22.9002 12.28H14.1102Z" fill="#822C61" />
            <path d="M25.8901 29.2198H17.1001C16.2501 29.2198 15.4901 29.0998 14.8401 28.8498C13.3701 28.2998 12.4101 27.1398 12.0801 25.4798C12.0301 25.2298 12.1101 24.9698 12.2901 24.7998C12.4701 24.6198 12.7301 24.5498 12.9801 24.6098C13.3101 24.6798 13.6801 24.7198 14.1001 24.7198H22.8901C25.3501 24.7198 26.5401 23.5299 26.5401 21.0699V15.9198C26.5401 15.4998 26.5101 15.1298 26.4401 14.7898C26.3901 14.5398 26.4701 14.2898 26.6401 14.1098C26.8201 13.9298 27.0701 13.8498 27.3201 13.9098C29.7201 14.3998 31.0401 16.1798 31.0401 18.9198V24.0699C31.0401 27.3499 29.1701 29.2198 25.8901 29.2198ZM13.9201 26.2198C14.2401 26.7998 14.7201 27.2099 15.3801 27.4499C15.8601 27.6299 16.4401 27.7198 17.1101 27.7198H25.9001C28.3601 27.7198 29.5501 26.5299 29.5501 24.0699V18.9198C29.5501 17.3398 29.0601 16.2899 28.0501 15.7399C28.0501 15.7999 28.0501 15.8598 28.0501 15.9198V21.0699C28.0501 24.3399 26.1701 26.2198 22.9001 26.2198H14.1101C14.0401 26.2198 13.9801 26.2198 13.9201 26.2198Z" fill="#822C61" />
            <path d="M18.4999 21.8899C16.6299 21.8899 15.1099 20.3699 15.1099 18.4999C15.1099 16.6299 16.6299 15.1099 18.4999 15.1099C20.3699 15.1099 21.8899 16.6299 21.8899 18.4999C21.8899 20.3699 20.3699 21.8899 18.4999 21.8899ZM18.4999 16.6099C17.4599 16.6099 16.6099 17.4599 16.6099 18.4999C16.6099 19.5399 17.4599 20.3899 18.4999 20.3899C19.5399 20.3899 20.3899 19.5399 20.3899 18.4999C20.3899 17.4599 19.5399 16.6099 18.4999 16.6099Z" fill="#822C61" />
            <path d="M12.7803 21.4501C12.3703 21.4501 12.0303 21.1101 12.0303 20.7001V16.3C12.0303 15.89 12.3703 15.55 12.7803 15.55C13.1903 15.55 13.5303 15.89 13.5303 16.3V20.7001C13.5303 21.1101 13.2003 21.4501 12.7803 21.4501Z" fill="#822C61" />
            <path d="M24.21 21.4501C23.8 21.4501 23.46 21.1101 23.46 20.7001V16.3C23.46 15.89 23.8 15.55 24.21 15.55C24.62 15.55 24.96 15.89 24.96 16.3V20.7001C24.96 21.1101 24.63 21.4501 24.21 21.4501Z" fill="#822C61" />
        </svg>
        &nbsp;&nbsp;&nbsp;
        <span>حمایت مالی</span>
        </a></div>
        </ul>
        </nav>
        </>
        )
    }
    
    export default Nav
    