
// import { useEffect, useState } from "react"
import { usePlayerStatContext } from "./PlayerStatProvider"
import OghatList from "./OghatList";
import saat from '../static/img/saat_web.png'

function OghatShari(){
    const { time, region, regionList, oghat, activeOghat, approchingEvent } = usePlayerStatContext()
    const currTime = time[0]
    const date = currTime.getDate().toLocaleString({ minimumIntegerDigits: 2 });
    const month = (currTime.getMonth() + 1).toLocaleString({ minimumIntegerDigits:2});
    const year = currTime.getFullYear();
    // const currMin = currTime.getHours()*60 + currTime.getMinutes();
    const city = region[1];
    // const city_id = city[0];
    const country = region[0];
    const cityList = regionList[1];
    const approchingOghat = approchingEvent[0];
    const month_str = ['ژانویه', 'فوریه', 'مارچ', 'آپریل', 'می', 'جون', 'جولای',
    'آگوست', 'سپتامبر', 'اکتبر', 'نوامبر', 'دسامبر'];
    const approching_oghat_str = {
        sunrise: 'طلوع آفتاب', zohr: 'اذان ظهر', sunset: 'غروب خورشید',
        maghreb: 'اذان مغرب', nime_shab: 'نیمه شب', azan_sobh: 'اذان صبح'
    };
    // const [oghat, setOghat] = useState(null);
    // const [activeOghat, setActiveOghat] = useState(null)
    
    // function returnMinutes(time){
    //     const sep_time = time.split(':')
    //     return (+sep_time[0]*60) + (+sep_time[1])
    // }
    
    
    // useEffect(()=>{
    //     setOghat(null)
    //     if (city_id != null){
    //         const getOghatURL = `https://m.radioramezan.com/api/owghat.php?city=${city_id}&date=${year + '-' + month + '-' + date}&method=0`
    //         // console.log(getOghatURL);
    //         fetch(getOghatURL)
    //         .then((res)=> res.json())
    //         .then((json)=>{
    //             setOghat(json);
    //         })
    //     }
    // }, [city_id, date, month, year])
    // useEffect(()=>{
    //     if(oghat != null){
    //         if (returnMinutes(oghat.azan_sobh) <= currMin && returnMinutes(oghat.sunrise) >= currMin){
    //             setActiveOghat('sunrise');
    //         } else if (returnMinutes(oghat.sunrise) <= currMin && returnMinutes(oghat.zohr) >= currMin){
    //             setActiveOghat('zohr');
    //         } else if (returnMinutes(oghat.zohr) <= currMin && returnMinutes(oghat.sunset) >= currMin){
    //             setActiveOghat('sunset');
    //         } else if (returnMinutes(oghat.sunset) <= currMin && returnMinutes(oghat.maghreb) >= currMin){
    //             setActiveOghat('maghreb');
    //         } else if (returnMinutes(oghat.maghreb) <= currMin && returnMinutes(oghat.nime_shab) >= currMin){
    //             setActiveOghat('nime_shab');
    //         } else {
    //             setActiveOghat('azan_sobh');
    //         }
    //     } else {
    //         setActiveOghat(null);
    //     }
    // },[oghat, currMin])
    return (
        <div className="oghat-container">
        <div className="oghat-list-img-container">
            <img className="oghat-list-saat-img" src={saat} alt="saat" width="80%" />
        </div>
        <div className="oghat-title">
        <h5>{`اوقات شرعی امروز ${date} ${month_str[+month-1]} ${year}`}</h5>
        </div>
            <div className="approching-oghat">
                <div className="digits-container" style={{ textAlign: 'center', fontSize: '.8em', paddingTop: '1px' }}>
                    <span>{approchingOghat != null ? approchingOghat[0] : '--'}</span>
                    <span className={(currTime.getSeconds() % 2 === 0 || activeOghat == null) ? 'visibile-cl' : 'hidden-cl'}>:</span>
                    <span>{approchingOghat != null ? approchingOghat[1] : '--'}</span>
                </div>
                <div className="approch-oghat-str" style={{ textAlign: 'center' }}>
                    <small>
                        <span> تا </span>
                        <span>{activeOghat != null ? approching_oghat_str[activeOghat] : '--'}</span>
                    </small>
                </div>
            </div>
        <div className="oghat-region">
        <span>{city[1] != null && country[1] != null && cityList != null ? `(شهر ${city[1]} ${country[1]})` : ''}</span>
        </div>
        <div className="oghat-time-list">
        <OghatList oghat={oghat} activeOghat={activeOghat} cityList={cityList}/>
        </div>
        </div>
        )
    }
    
    export default OghatShari