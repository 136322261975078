import { useState } from 'react';
import aboutus from '../static/txt/about-us.txt'
import LoadingSpinner from '../components/LoadingSpinner';
function AboutUs(){
    const [aboutReady, setAboutReady] = useState(false)
    const txtStyle = {
        whiteSpace : 'pre-line'
    }
    fetch(aboutus)
    .then(res=> res.text())
    .then(txt => {
        // let tmp = txt.replace(/\r\n/g,'<p></p>');
        setAboutReady(txt)
    })
    .catch(err=>{
        console.log(err)
    })
    return (
        <div className='about-us-container'>
            <div className="box-container">
            <h1>درباره ما</h1>
            {
                aboutReady !== false
                        ? <p style={txtStyle}>{`${aboutReady}`}</p>
                : <LoadingSpinner />
            }
            </div>
        </div>
    )

}

export default AboutUs