import { useEffect } from "react";
import CustomSelector from "./CustomeSelector"
import LoadingSpinner from "./LoadingSpinner";
import { usePlayerStatContext } from "./PlayerStatProvider"
function RegionSelector(){
    const headerStyle = {
        marginTop: '.1em',
        marginBottom: '.1em',
    }
    const {regionList, setRegion, region, player} = usePlayerStatContext();
    const [country, city] = region;
    const [countryList, cityList] = regionList;
    const [setCountryList, setCityList] = setRegion;
    const setPlayerStat = player[1];
    const city_id = city[0];
    
    useEffect(()=>{
        if(city_id == null){
            setPlayerStat(false)
        }
    },[city_id,setPlayerStat])
    return(
        <div className="region-selector-container">
        <h4 style={headerStyle}>موقعیت مکانی:</h4>
        {
            cityList != null ? 
            <>
            <div className="selectors-container">
            <CustomSelector name="country" label="کشور" options={countryList} choice={country} setChoice={setCountryList} />
            <CustomSelector name="city" label="شهر" options={cityList} choice={city} setChoice={setCityList} />
            </div>
            </> : 
            <LoadingSpinner />
        }
        </div>
        )
    }
    
    
    export default RegionSelector